import React, { useContext, useEffect, useRef, useState } from 'react';
import Header from '../Header';
import AsideBar from '../AsideBar';
import Footer from '../Footer';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { URL_AXIOS, URL_FOLDER_API, URL_MAIN } from '../../URL/URL';
import axios from 'axios';
import { InputText } from 'primereact/inputtext';
import { Divider } from 'primereact/divider';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Dialog } from 'primereact/dialog';
import { ReactCrop } from 'react-image-crop';
import { Editor } from "primereact/editor";
import { confirmDialog } from 'primereact/confirmdialog';
import { ThemeContext } from '../../context/ContextHeader';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import JoditEditor from 'jodit-react';
import { useMemo } from 'react';
import { Jodit } from 'jodit';
import reactSessionApi from 'react-session-api';
import Loading from './Loading';
function DetailDossiersEnvoye() {
    const location = useLocation();
    const editorRef = useRef(null);
    window.editorRef = editorRef;
    const nom = "NKAYA";
    window.nom = nom;


    const navigate = useNavigate();
    const {
        setDossiersArchive, disable,
        setDisable, disableAvis,
        setDisableAvis, medecinID,
        setMedecinID, contentMrc,
        setContentMrc, statutView,
        titles, setTitles
    } = useContext(ThemeContext);
    // const usernameconnected = reactSessionApi.get("nom");
    // const membre_id = reactSessionApi.get("membre_id");
    // const user_id = reactSessionApi.get("user_id");
    const usernameconnected = sessionStorage.getItem("nom");
    const user_id = parseInt(sessionStorage.getItem("user_id"));
    const membre_id = parseInt(sessionStorage.getItem("membre_id"));
    const [LoadingState, setLoadingState] = useState(false);
    const [LoadingStateFolder, setLoadingStateFolder] = useState(false);
    const [visibleMcr, setVisibleMcr] = useState(false);
    const [VisibleTech, setVisibleTech] = useState(false);
    const [VisibleRaccourcis, setVisibleRaccourcis] = useState(false);
    const [visibleModal, setVisibleModal] = useState(false);
    const [position, setPosition] = useState('center');
    const editor = useRef(null);
    const [content, setContent] = useState('');
    const [isRecording, setIsRecording] = useState(false);
    const recognitionRef = useRef(null);
    const [editorContent, setEditorContent] = useState("");
    const [visibleObserva, setVisibleObserva] = useState(false);
    const [observationDossier, setObservationDossier] = useState("");
    const [prevTranscript, setPrevTranscript] = useState("");
    const prevTranscriptRef = useRef("");
    const [observation, setObservation] = useState("");
    const { id, avis_id } = useParams();
    const mirrorRef = useRef(null);
    const [visible, setVisible] = useState(false);
    const [siteName, setSiteName] = useState("");
    const [statutUrg, setStatutUrg] = useState(1);
    const [categorie_fichier, setCategorie_fichier] = useState("");
    const [Examen_1, setExamen_1] = useState('');
    const [Examen_2, setExamen_2] = useState('');
    const [chemin_fichier_1, setChemin_fichier_1] = useState('');
    const [chemin_fichier_2, setChemin_fichier_2] = useState('');
    const [bulletin_examen, setBulletin_examen] = useState('');
    const [chemin_bulletin, setChemin_bulletin] = useState('');
    const [nom_pat, setNom_pat] = useState('');
    const [age, setAge] = useState("");
    const [LibelleAge, setLibelleAge] = useState("");
    const [sexe, setSexe] = useState("");
    const [prescripteur, setPrescripteur] = useState("");
    const [src, setSrc] = useState(null);
    const [crop, setCrop] = useState({ aspect: 16 / 9 });
    const [image, setImage] = useState(null);
    const [output, setOutput] = useState(null);
    const [src_1, setSrc_1] = useState(null);
    const [crop_1, setCrop_1] = useState({ aspect: 16 / 9 });
    const [image_1, setImage_1] = useState(null);
    const [output_1, setOutput_1] = useState(null);
    const [src_2, setSrc_2] = useState(null);
    const [crop_2, setCrop_2] = useState({ aspect: 16 / 9 });
    const [image_2, setImage_2] = useState(null);
    const [output_2, setOutput_2] = useState(null);
    const [statusSave, setStatusSave] = useState(true);
    const [patientId, setPatientId] = useState("");
    const [statut, setStatut] = useState("");
    const [etat, setEtat] = useState("");
    const [inter_id, setInter_id] = useState('');
    const [obs, setObs] = useState("");
    const [mcr, setMcr] = useState([]);
    const [statusLoad, setStatusLoad] = useState(false);
    const [mcrId, setMcrId] = useState(0);
    const [textearea, setTextearea] = useState("");
    const [transcription, setTranscription] = useState('');
    const [isListening, setIsListening] = useState(false);
    const [exp, setExp] = useState("");
    const [titleGet, setTitleGet] = useState("");
    const [img_obs_1, setImg_obs_1] = useState("");
    const [img_obs_2, setImg_obs_2] = useState("");
    const [img_obs_3, setImg_obs_3] = useState("");
    let joditEditor = null;

    useEffect(() => {
        const handleTranscriptionUpdate = (event) => {
            setTranscription(event.detail);
            console.log("Transcription updated:", event.detail);
        };
        document.addEventListener('transcriptionUpdated', handleTranscriptionUpdate);
        return () => {
            document.removeEventListener('transcriptionUpdated', handleTranscriptionUpdate);
        };
    }, []);

    useEffect(() => {
        if (editorRef.current) {
            joditEditor = Jodit.make(editorRef.current);
            joditEditor.selection.focus();
            joditEditor.selection.insertHTML(transcription);
        }
    }, [transcription]);

    function BtnListeningVoiceToText(e) {
        try {
            if (isListening) {
                window.stopListening();
                setIsListening(false);
                // if (editorRef.current) {
                //     joditEditor = Jodit.make(editorRef.current);
                //     joditEditor.selection.focus();

                // }
            } else {
                window.startButton(e)
                setIsListening(true);
            }
            // isListening = false;
            // recognition.stop();

        } catch (error) {

        }
    }
    const config = useMemo(
        () => ({
            style: {
                height: '500px',
            },
            allowResizeX: false,
            allowResizeY: false,
            autofocus: false,
            toolbarAdaptive: false,
            editorClassName: 'editorComponent',
            // autofocus: false,
            // cursorAfterAutofocus: 'start', // 'end';
            // saveSelectionOnBlur: true,
            // hidePoweredByJodit: false,
            // zIndex: 0,
            readonly: false,
            // inline: false,
            //activeButtonsInReadOnly: ['source', 'fullsize', 'print', 'about', 'dots'],
            toolbarButtonSize: 'middle',
            theme: 'default',
            saveModeInStorage: true,
            // saveModeInCookie: true,
            spellcheck: true,
            // editorCssClass: true,
            // triggerChangeEvent: false,
            width: 'auto',
            // direction: '',
            enter: "br",
            // editHTMLDocumentMode: true,
            cursorAfterAutofocus: "end",
            // saveSelectionOnBlur: true,
            // nl2brInPlainText: true,
            // sourceEditor: "area",
            language: 'auto',
            // debugLanguage: false,
            i18n: 'fr',
            tabIndex: -1,
            toolbar: true,
            useSplitMode: false,
            colors: {
                greyscale: ['#000000', '#434343', '#666666', '#999999', '#B7B7B7', '#CCCCCC', '#D9D9D9', '#EFEFEF', '#F3F3F3', '#FFFFFF'],
                palette: ['#980000', '#FF0000', '#FF9900', '#FFFF00', '#00F0F0', '#00FFFF', '#4A86E8', '#0000FF', '#9900FF', '#FF00FF'],
                full: [
                    '#E6B8AF', '#F4CCCC', '#FCE5CD', '#FFF2CC', '#D9EAD3', '#D0E0E3', '#C9DAF8', '#CFE2F3', '#D9D2E9', '#EAD1DC',
                    '#DD7E6B', '#EA9999', '#F9CB9C', '#FFE599', '#B6D7A8', '#A2C4C9', '#A4C2F4', '#9FC5E8', '#B4A7D6', '#D5A6BD',
                    '#CC4125', '#E06666', '#F6B26B', '#FFD966', '#93C47D', '#76A5AF', '#6D9EEB', '#6FA8DC', '#8E7CC3', '#C27BA0',
                    '#A61C00', '#CC0000', '#E69138', '#F1C232', '#6AA84F', '#45818E', '#3C78D8', '#3D85C6', '#674EA7', '#A64D79',
                    '#85200C', '#990000', '#B45F06', '#BF9000', '#38761D', '#134F5C', '#1155CC', '#0B5394', '#351C75', '#733554',
                    '#5B0F00', '#660000', '#783F04', '#7F6000', '#274E13', '#0C343D', '#1C4587', '#073763', '#20124D', '#4C1130'
                ]
            },

            colorPickerDefaultTab: 'background',
            removeButtons: [],
            disablePlugins: [],
            extraButtons: [],
            sizeLG: 900,
            sizeMD: 700,
            statusbar: false,
            // shadowRoot: null,
            //sizeSM: 400,
            //sizeSM: 400,
            speechRecognize: {
                lang: 'fr-FR',
                commands: {
                    'newline|enter': 'enter',
                    'delete|remove word|delete word': 'backspaceWordButton',
                    point: 'inserthtml::,',
                    underline: 'inserthtml::_',
                    hyphen: 'inserthtml::-',
                    space: 'inserthtml:: ',
                    question: 'inserthtml::?',
                    dot: 'inserthtml::.',
                    'quote|quotes|open quote': "inserthtml::'",
                    'header|header h1': 'formatblock::h1',
                    'select all': 'selectall'
                }
            },
            buttons: [
                'source', '|',
                'bold',
                'strikethrough',
                'underline',
                'italic', '|',
                'ul',
                'ol', '|',
                'outdent', 'indent', '|',
                'font',
                'fontsize',
                'brush',
                'paragraph', '|',
                'table',
                'link', '|',
                'align', 'undo', 'redo', '|',
                'hr',
                'eraser',
                'print',



            ],
            extraButtons: [
                {
                    name: 'customSpeechRecognition',
                    iconURL: isListening ? "../../miciconmuted.png" : "../../micoff2.png", // Use a microphone icon URL
                    exec: (e) => BtnListeningVoiceToText(e),
                    tooltip: 'Démarrer / Arrêter enregistrement',

                },
                isListening ? 'Enregistrement est en cours ...' : ''
            ]
            ,

            /*buttonsXS: [
                'bold',
                'image', '|',
                'paragraph', '|',
                'align', '|',
                'undo', 'redo', '|',
                'eraser',
                'dots'
            ],*/
            events:
            {

            },

            textIcons: false,
        }),
        [isListening]
    );


    const toast = useRef(null);
    const NEW_LINE = '\n';

    // const liste_dossiers_interprete = async (dossier_id) => {
    //     try {
    //         const response = await axios.get(URL_AXIOS + `liste_interpretation/${parseInt(dossier_id)}`);
    //         if (response.status === 200) {

    //             const obs = response.data.dossierInter.observation;
    //             setObservation(obs);
    //             if (response.data.dossierInter.img_obs_1) {
    //                 const URL1 = URL_MAIN + response.data.dossierInter.img_obs_1;
    //                 setImg_obs_1(URL1);
    //             } else {
    //                 setImg_obs_1("");
    //             }
    //             if (response.data.dossierInter.img_obs_2) {
    //                 const URL2 = URL_MAIN + response.data.dossierInter.img_obs_2;
    //                 setImg_obs_2(URL2);
    //             } else {
    //                 setImg_obs_2("");
    //             }
    //             if (response.data.dossierInter.img_obs_3) {
    //                 const URL3 = URL_MAIN + response.data.dossierInter.img_obs_3;
    //                 setImg_obs_3(URL3);
    //             } else {
    //                 setImg_obs_3("");
    //             }
    //             setInter_id(response.data.dossierInter.inter_id);
    //         }

    //     } catch (error) {
    //         console.log("Erreur " + error.message);
    //     }
    // }

    const list_mcr = async (membre_id) => {
        try {
            const response = await axios.get(URL_AXIOS + `list_mcrByMember/${membre_id}`);
            if (response.data.status === 200) {
                setMcr(response.data.mcr);
            }
        } catch (Error) {

        }
    }
    const liste_dossiers_avis_placeholder = async (id_membre) => {
        try {

            const response = await axios.get(URL_AXIOS + `liste_avis_sollicitePlaceHolder/${id_membre}`);
            if (response.data.status === 200) {

                const dataDossier = response.data.data_avis_solliciteByFolder;
                // const newTitles = {};
                // for (const [dossier_id, avisList] of Object.entries(dataDossier)) {
                //     newTitles[dossier_id] = avisList.map(avis =>(avis.nom_recep+' '+avis.prenom_recep)).join(', '); // Replace 'someField' with the actual field name you want to use
                // }
                setTitles(dataDossier);


            } else {
                alert("Error in API response");
            }

        } catch (error) {
            console.error("Error:", error.message);
            alert("An error occurred. Please check the console for details.");
        }
    }
    const RechercheMcr = async (e) => {
        try {
            const value = e.target.value;
            if (value !== "") {
                const response = await axios.post(URL_AXIOS + `list_mcr_rechercheByMember/${membre_id}/${value}`);
                if (response.data.status === 200) {
                    setMcr(response.data.mcr);
                }
            } else {
                list_mcr(membre_id);
            }

        } catch (Error) {

        }
    }

    const deleteMcr = async (id) => {
        try {
            const response = await axios.post(URL_AXIOS + `delete_mcr/${id}`);
            if (response.data.status === 200) {
                list_mcr(membre_id);

            }
        } catch (error) {

        }
    }
    const deleteConfirmMrc = (id) => {
        try {
            Swal.fire({
                title: "Question ?",
                text: "Êtes-vous sûr de vouloir supprimer ce compte rendu",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Oui",
                cancelButtonText: "Non"
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        title: "Réussi !",
                        text: "Le compte rendu a été supprimé avec succès !",
                        icon: "success"
                    });
                    deleteMcr(id);
                }
            });
        } catch (error) {

        }
    }

    const selectImage = (file) => {
        setSrc(URL.createObjectURL(file));
    };
    const selectImage_1 = (file) => {
        setSrc_1(URL.createObjectURL(file));
    };
    const selectImage_2 = (file) => {
        setSrc_2(URL.createObjectURL(file));
    };

    const insertLoad = (text) => {
        // setEditorContent(prevContent => prevContent + ' ' + text);
        if (editorRef.current) {
            const joditEditor = Jodit.make(editorRef.current);
            joditEditor.selection.insertHTML(text);
        }

    };
    const insertSymbol = (text) => {
        // setEditorContent(prevContent => prevContent + ' ' + text);
        if (editorRef.current) {
            const joditEditor = Jodit.make(editorRef.current);
            joditEditor.selection.insertHTML(text);
        }
        setVisibleTech(false);
    };

    const insertSymbolRaccourcis = (text) => {
        // setEditorContent(prevContent => prevContent + ' ' + text);
        if (editorRef.current) {
            const joditEditor = Jodit.make(editorRef.current);
            joditEditor.selection.insertHTML(text);
        }
        setVisibleRaccourcis(false);
    };
    const insertMrc = (text) => {
        // setEditorContent(prevContent => prevContent + ' ' + text);
        if (editorRef.current) {
            const joditEditor = Jodit.make(editorRef.current);
            joditEditor.selection.insertHTML(text);
        }
        setVisibleMcr(false);
    };
    const addMcr = async () => {
        try {
            //var editeurValue = document.querySelector(".editor").innerHTML;
            setVisibleModal(false);
            if (editorRef.current.value !== "" && categorie_fichier != "") {

                const data = {
                    titre: categorie_fichier,
                    observation: editorRef.current.value,
                    statut: '0',
                    id_membre: membre_id,

                }
                const response = await axios.post(URL_AXIOS + 'addMcr', data);
                if (response.data.status === 200) {
                    Swal.fire({
                        title: 'Réussi',
                        text: 'Le modèle compte rendu a été enregistré avec succès !',
                        icon: 'success',

                    })

                    list_mcr(membre_id);

                } else {
                    //alert("Erreur savings")
                }



            } else {
                Swal.fire({
                    title: 'Erreur',
                    text: 'Veuillez mettre le titre du compte rendu svp !',
                    icon: 'error',

                })

            }
        } catch (error) {
            //alert("erreur 12" + error)
        }
    }

    const cropImageNow = () => {
        const imageL = document.getElementById("cropp");
        const canvas = document.createElement('canvas');
        const scaleX = imageL.naturalWidth / imageL.width;
        const scaleY = imageL.naturalHeight / imageL.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        const pixelRatio = window.devicePixelRatio;
        canvas.width = crop.width * pixelRatio;
        canvas.height = crop.height * pixelRatio;
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            imageL,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height,
        );

        // Converting to base64
        const base64Image = canvas.toDataURL('image/jpeg');
        setOutput(base64Image);
    };

    const cropImageNow_1 = () => {
        const imageL = document.getElementById("cropp1");
        const canvas = document.createElement('canvas');
        const scaleX = imageL.naturalWidth / imageL.width;
        const scaleY = imageL.naturalHeight / imageL.height;
        canvas.width = crop_1.width;
        canvas.height = crop_1.height;
        const ctx = canvas.getContext('2d');

        const pixelRatio = window.devicePixelRatio;
        canvas.width = crop_1.width * pixelRatio;
        canvas.height = crop_1.height * pixelRatio;
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            imageL,
            crop_1.x * scaleX,
            crop_1.y * scaleY,
            crop_1.width * scaleX,
            crop_1.height * scaleY,
            0,
            0,
            crop_1.width,
            crop_1.height,
        );

        // Converting to base64
        const base64Image = canvas.toDataURL('image/jpeg');
        setOutput_1(base64Image);
    };

    const cropImageNow_2 = () => {
        const imageL = document.getElementById("cropp2");
        const canvas = document.createElement('canvas');
        const scaleX = imageL.naturalWidth / imageL.width;
        const scaleY = imageL.naturalHeight / imageL.height;
        canvas.width = crop_2.width;
        canvas.height = crop_2.height;
        const ctx = canvas.getContext('2d');

        const pixelRatio = window.devicePixelRatio;
        canvas.width = crop_2.width * pixelRatio;
        canvas.height = crop_2.height * pixelRatio;
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            imageL,
            crop_2.x * scaleX,
            crop_2.y * scaleY,
            crop_2.width * scaleX,
            crop_2.height * scaleY,
            0,
            0,
            crop_2.width,
            crop_2.height,
        );

        // Converting to base64
        const base64Image = canvas.toDataURL('image/jpeg');
        setOutput_2(base64Image);
    };

    // const liste_dossiers_by_siteIdAvis = async (id) => {
    //     try {
    //         const response = await axios.get(URL_AXIOS + `liste_avis_solliciteByAvisId/${parseInt(id)}`);
    //         if (response.data.status === 200) {
    //             // setDossiers(response.data.dossier_send);
    //             setEditorContent(response.data.data_avis_sollicite.observation);
    //             setStatut(response.data.data_avis_sollicite.statut_avis);

    //             setObservationDossier(response.data.data_avis_sollicite.observation);
    //             // alert(response.data.data_avis_sollicite.observation_avis)

    //         } else {
    //             alert("ERREUR LOAD AVIS")
    //         }

    //     } catch (error) {
    //         //alert("Erreur " + error.message);
    //     }
    // }
    const liste_dossiers_by_siteId = async (dossier_id) => {
        try {
            const response = await axios.get(URL_AXIOS + `liste_dossiers_by_dossiersId/${parseInt(dossier_id)}`);
            if (response.data.status === 200) {
                // setDossiers(response.data.dossier_send);
                setLoadingStateFolder(true)
                setSiteName(response.data.dossier_send.societe);
                setCategorie_fichier(response.data.dossier_send.categorie_fichier);
                setExamen_1(response.data.dossier_send.nom_fichier);
                setExamen_2(response.data.dossier_send.nom_fichier_2);
                setChemin_fichier_1(response.data.dossier_send.chemin_fichier);
                setChemin_fichier_2(response.data.dossier_send.chemin_fichier_2);
                setBulletin_examen(response.data.dossier_send.bulletin_examen);
                setChemin_bulletin(response.data.dossier_send.chemin_bulletin);
                setNom_pat(response.data.dossier_send.nom_pat);
                setAge(response.data.dossier_send.age_pat);
                setSexe(response.data.dossier_send.sexe_pat);
                setLibelleAge(response.data.dossier_send.libelle_age_pat);
                setPrescripteur(response.data.dossier_send.prescripteur);
                setExp(response.data.dossier_send.exp)
                setEtat(response.data.dossier_send.etat);
                // if (avis_id === null || avis_id === undefined) {
                setStatut(response.data.dossier_send.statut);
                setObservationDossier(response.data.dossier_send.observation)
                // }


            } else {

            }

        } catch (error) {
            //alert("Erreur " + error.message);
        }
    }
    const enregistrerDossier = () => {
        if (id !== null) {
            if (statut == "0") {
                AddInter();

            } else {
                setVisibleModal(true);

            }
        }
    }
    const liste_dossiers_by_siteEnteteAccordionArchives = async (id) => {
        try {
            const response = await axios.get(URL_AXIOS + `liste_dossiers_by_site_entete_archive/${id}`);
            if (response.data.status == 200) {
                setDossiersArchive(response.data.dossier_send);
                //setDossiersSousArchive(response.data.data_dossier_sub);
                //setNbFoldersSend(response.data.data_dossier_num.length)

            }
        } catch (error) {

        }
    }
    const envoyerFolderMethode = async (val) => {
        try {

            if (val !== null) {
   
                if (editorRef.current.value !== "" || editorRef.current.value !== null) {
                    if (editorRef.current) {
                        joditEditor = Jodit.make(editorRef.current);
                        joditEditor.selection.focus();
                    }
                   
                    const data = {
                        titre: categorie_fichier,
                        observation: editorRef.current.value,
                        nom_patient: nom_pat,
                        statut: 2,
                        dossier_id: id,
                        societe: siteName,
                        inter_id: inter_id
                    }
                    const response = await axios.post(URL_AXIOS + `envoyer_dossier/${val}`, data, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    });
                    if (response.data.status == 200) {
                        Swal.fire({
                            title: 'Réussi',
                            text: 'Le dossier a été envoyé avec succès !',
                            icon: 'success',

                        });
                        liste_dossiers_by_siteEnteteAccordionArchives(membre_id);
                        navigate('/resultat');

                    } else {
                    }
                } else {

                }
            }

        } catch (error) {

        }
    }

    // const envoyerFolderCE = async (val) => {
    //     try {

    //         if (val !== null) {

    //             if (editorRef.current.value !== "" || editorRef.current.value !== null) {
    //                 // // Fetch the blob from output URL and create File object
    //                 // const blob = output ? await fetch(output).then(res => res.blob()) : null;
    //                 // const imageFile = blob ? new File([blob], `image_1.jpg`, { type: 'image/jpeg' }) : null;

    //                 // // Fetch the blob from output_1 URL and create File object
    //                 // const blob_1 = output_1 ? await fetch(output_1).then(res => res.blob()) : null;
    //                 // const imageFile_1 = blob_1 ? new File([blob_1], `image_2.jpg`, { type: 'image/jpeg' }) : null;

    //                 // // Fetch the blob from output_2 URL and create File object
    //                 // const blob_2 = output_2 ? await fetch(output_2).then(res => res.blob()) : null;
    //                 // const imageFile_2 = blob_2 ? new File([blob_2], `image_3.jpg`, { type: 'image/jpeg' }) : null;

    //                 // Create the data object
    //                 const data = {
    //                     titre_avis: categorie_fichier,
    //                     observation_avis: editorRef.current.value,
    //                     statut_avis: '2',
    //                     avis_id: avis_id,
    //                     // img_obs_1_avis: imageFile,
    //                     // img_obs_2_avis: imageFile_1,
    //                     // img_obs_3_avis: imageFile_2,
    //                 };

    //                 const response = await axios.post(URL_AXIOS + 'addAvisInterpretation', data, {
    //                     headers: {
    //                         'Content-Type': 'multipart/form-data',
    //                     },
    //                 });
    //                 if (response.data.status == 200) {
    //                     Swal.fire({
    //                         title: 'Réussi ',
    //                         text: 'Le dossier a été envoyé avec succès !',
    //                         icon: 'success',

    //                     });

    //                     navigator('/compte_expertise');

    //                 } else {
    //                     //alert("Le dossier est vide dfjkfkjef ");
    //                 }
    //             } else {
    //                 //alert("Le dossier est vide cpcp");
    //             }
    //         }

    //     } catch (error) {

    //     }
    // }
    const EnvoyerDossierConfirmation = () => {
        try {

            Swal.fire({
                title: "Voulez vous envoyer ce dossier?",
                showCancelButton: true,
                confirmButtonText: "Oui",
                cancelButtonText: "Non",
                icon: 'question'
            }).then((result) => {

                if (result.isConfirmed) {

                    envoyerFolderMethode(id)
                    liste_dossiers_by_siteId(id);

                } else if (result.isCanceled) {
                    Swal.fire("Le dossier n'a pas été envoyé", "", "info");
                }
            });

        } catch (error) {

        }
    }
    // const EnvoyerDossierConfirmationCE = () => {
    //     try {

    //         Swal.fire({
    //             title: "Voulez vous envoyer ce dossier?",
    //             showCancelButton: true,
    //             confirmButtonText: "Oui",
    //             cancelButtonText: "Non",
    //             icon: 'question'
    //         }).then((result) => {

    //             if (result.isConfirmed) {

    //                 envoyerFolderCE(id)
    //                 liste_dossiers_by_siteId(id);

    //             } else if (result.isCanceled) {
    //                 Swal.fire("Le dossier n'a pas été envoyé", "", "info");
    //             }
    //         });

    //     } catch (error) {

    //     }
    // }
    const handleDownload = (e, value) => {
        e.preventDefault();
        const link = document.createElement('a');
        link.download = 'Example-PDF-File';

        link.href = value;

        link.click();
    };

    const handleOpen = (e, value) => {
        e.preventDefault();
        const link = document.createElement('a');
        //link.download = 'Example-PDF-File';

        link.href = value;
        link.target = "_blank";

        link.click();
    };

    const handleApercu = (e, value) => {
        e.preventDefault();
        const link = document.createElement('a');
        //link.download = 'Example-PDF-File';

        link.href = `/apercu/${id}`;
        link.target = "_blank";

        link.click();
    };

    const addInterpretation = async () => {
        try {

            if (editorRef.current.value !== "" || editorRef.current.value !== null) {
                // Fetch the blob from output URL and create File object
                const blob = output ? await fetch(output).then(res => res.blob()) : null;
                const imageFile = blob ? new File([blob], `image_1.jpg`, { type: 'image/jpeg' }) : null;

                // Fetch the blob from output_1 URL and create File object
                const blob_1 = output_1 ? await fetch(output_1).then(res => res.blob()) : null;
                const imageFile_1 = blob_1 ? new File([blob_1], `image_2.jpg`, { type: 'image/jpeg' }) : null;

                // Fetch the blob from output_2 URL and create File object
                const blob_2 = output_2 ? await fetch(output_2).then(res => res.blob()) : null;
                const imageFile_2 = blob_2 ? new File([blob_2], `image_3.jpg`, { type: 'image/jpeg' }) : null;

                // Create the data object
                const data = {
                    titre: categorie_fichier,
                    observation: editorRef.current.value,
                    nom_patient: nom_pat,
                    statut: "1",
                    img_obs_1: imageFile,
                    img_obs_2: imageFile_1,
                    img_obs_3: imageFile_2,
                    societe: siteName,
                    inter_id: inter_id,
                    dossier_id: id
                };
                // const blob = await fetch(output).then((res) => res.blob());
                // const imageFile = new File([blob], `image_1.jpg`, { type: 'image/jpeg' });
                // const blob_1 = await fetch(output_1).then((res) => res.blob());
                // const imageFile_1 = new File([blob_1], `image_2.jpg`, { type: 'image/jpeg' });
                // const blob_2 = await fetch(output_2).then((res) => res.blob());
                // const imageFile_2 = new File([blob_2], `image_3.jpg`, { type: 'image/jpeg' });
                // const data = {
                //     titre: categorie_fichier,
                //     observation: content,
                //     nom_patient: nom_pat,
                //     statut: "1",
                //     img_obs_1: (output !== null ? imageFile : ""),
                //     img_obs_2: (output_1 !== null ? imageFile_1 : ""),
                //     img_obs_3: (output_2 !== null ? imageFile_2 : ""),
                //     societe: siteName,
                //     inter_id: inter_id,
                //     dossier_id: id
                // }

                const response = await axios.post(URL_AXIOS + 'addInterpretation', data, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                if (response.data.status === 200) {

                    setStatusSave(false);
                    liste_dossiers_by_siteId(id);
                    setSrc("");
                    setSrc_1("");
                    setSrc_2("");
                    setOutput(null);
                    setOutput_1(null);
                    setOutput_2(null);


                } else {
                    //  alert("Erreur 12")

                }
            } else {

            }
        } catch (error) {
            alert("Erreur " + error.message);
        }
    }


    const UpdateInterpretationImage = async () => {
        try {

            if ((output !== "" && output !== null) || (output_1 !== "" && output_1 !== null) || (output_2 !== "" && output_2 !== null)) {
                // Fetch the blob from output URL and create File object
                const blob = output ? await fetch(output).then(res => res.blob()) : null;
                const imageFile = blob ? new File([blob], `image_1.jpg`, { type: 'image/jpeg' }) : null;

                // Fetch the blob from output_1 URL and create File object
                const blob_1 = output_1 ? await fetch(output_1).then(res => res.blob()) : null;
                const imageFile_1 = blob_1 ? new File([blob_1], `image_2.jpg`, { type: 'image/jpeg' }) : null;

                // Fetch the blob from output_2 URL and create File object
                const blob_2 = output_2 ? await fetch(output_2).then(res => res.blob()) : null;
                const imageFile_2 = blob_2 ? new File([blob_2], `image_3.jpg`, { type: 'image/jpeg' }) : null;


                // Create the data object
                if (id !== null) {
                    // Create the data object
                    const data = {
                        titre: categorie_fichier,
                        nom_patient: nom_pat,
                        statut: "1",
                        img_obs_1: imageFile,
                        img_obs_2: imageFile_1,
                        img_obs_3: imageFile_2,
                        societe: siteName,
                        dossier_id: id,
                        observation: editorRef.current.value,
                    };

                    const response = await axios.post(URL_AXIOS + 'updateImages', data, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    });
                    if (response.data.status === 200) {


                        liste_dossiers_by_siteId(id);
                        Swal.fire("Les données ont été mises à jour avec succès !", "", "info");

                        setSrc("");
                        setSrc_1("");
                        setSrc_2("");
                        setOutput(null);
                        setOutput_1(null);
                        setOutput_2(null);
                        setVisible(false)



                    } else {


                    }
                }


            } else {

            }
        } catch (error) {
            //alert("Erreur " + error.message);
        }
    }

    const UpdateInterpretation = async () => {
        try {
            setVisibleModal(false);
            if (editorRef.current.value !== "" || editorRef.current.value !== null) {
                // Fetch the blob from output URL and create File object
                const blob = output ? await fetch(output).then(res => res.blob()) : null;
                const imageFile = blob ? new File([blob], `image_1.jpg`, { type: 'image/jpeg' }) : null;

                // Fetch the blob from output_1 URL and create File object
                const blob_1 = output_1 ? await fetch(output_1).then(res => res.blob()) : null;
                const imageFile_1 = blob_1 ? new File([blob_1], `image_2.jpg`, { type: 'image/jpeg' }) : null;

                // Fetch the blob from output_2 URL and create File object
                const blob_2 = output_2 ? await fetch(output_2).then(res => res.blob()) : null;
                const imageFile_2 = blob_2 ? new File([blob_2], `image_3.jpg`, { type: 'image/jpeg' }) : null;

                // Create the data object
                const data = {
                    titre: categorie_fichier,
                    observation: editorRef.current.value,
                    nom_patient: nom_pat,
                    statut: "1",
                    img_obs_1: imageFile,
                    img_obs_2: imageFile_1,
                    img_obs_3: imageFile_2,
                    societe: siteName,
                    inter_id: inter_id,
                    dossier_id: id
                };
                // const blob = await fetch(output).then((res) => res.blob());
                // const imageFile = new File([blob], `image_1.jpg`, { type: 'image/jpeg' });
                // const blob_1 = await fetch(output_1).then((res) => res.blob());
                // const imageFile_1 = new File([blob_1], `image_2.jpg`, { type: 'image/jpeg' });
                // const blob_2 = await fetch(output_2).then((res) => res.blob());
                // const imageFile_2 = new File([blob_2], `image_3.jpg`, { type: 'image/jpeg' });
                // const data = {
                //     titre: categorie_fichier,
                //     observation: content,
                //     nom_patient: nom_pat,
                //     statut: "1",
                //     img_obs_1: (output !== null ? imageFile : ""),
                //     img_obs_2: (output_1 !== null ? imageFile_1 : ""),
                //     img_obs_3: (output_2 !== null ? imageFile_2 : ""),
                //     societe: siteName,
                //     inter_id: inter_id,
                //     dossier_id: id
                // }

                const response = await axios.post(URL_AXIOS + 'addInterpretation', data, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                if (response.data.status === 200) {

                    setStatusSave(false);
                    liste_dossiers_by_siteId(id);
                    Swal.fire("Les données ont été mises à jour avec succès !", "", "info");

                    setSrc("");
                    setSrc_1("");
                    setSrc_2("");
                    setOutput(null);
                    setOutput_1(null);
                    setOutput_2(null);



                } else {
                    //alert("Erreur 12")

                }
            } else {

            }
        } catch (error) {
            //alert("Erreur " + error.message);
        }
    }
    // const SaveCE = async () => {
    //     try {

    //         if (editorRef.current.value !== "" || editorRef.current.value !== null) {

    //             // Fetch the blob from output URL and create File object
    //             const blob = output ? await fetch(output).then(res => res.blob()) : null;
    //             const imageFile = blob ? new File([blob], `image_1.jpg`, { type: 'image/jpeg' }) : null;

    //             // Fetch the blob from output_1 URL and create File object
    //             const blob_1 = output_1 ? await fetch(output_1).then(res => res.blob()) : null;
    //             const imageFile_1 = blob_1 ? new File([blob_1], `image_2.jpg`, { type: 'image/jpeg' }) : null;

    //             // Fetch the blob from output_2 URL and create File object
    //             const blob_2 = output_2 ? await fetch(output_2).then(res => res.blob()) : null;
    //             const imageFile_2 = blob_2 ? new File([blob_2], `image_3.jpg`, { type: 'image/jpeg' }) : null;

    //             // Create the data object
    //             const data = {
    //                 titre_avis: categorie_fichier,
    //                 observation_avis: editorRef.current.value,
    //                 statut_avis: '1',
    //                 avis_id: avis_id,
    //                 img_obs_1_avis: imageFile,
    //                 img_obs_2_avis: imageFile_1,
    //                 img_obs_3_avis: imageFile_2,
    //             };



    //             const response = await axios.post(URL_AXIOS + 'addAvisInterpretation', data, {
    //                 headers: {
    //                     'Content-Type': 'multipart/form-data',
    //                 },
    //             });
    //             if (response.data.status === 200) {
    //                 setStatusSave(false);
    //                 //  liste_dossiers_by_siteIdAvis(id);
    //                 // liste_dossiers_by_siteId(id)
    //                 setSrc("");
    //                 setSrc_1("");
    //                 setSrc_2("");
    //                 setOutput(null);
    //                 setOutput_1(null);
    //                 setOutput_2(null);
    //                 Swal.fire({
    //                     title: "Réussi !",
    //                     text: "L'avis a été bien enregistré avec succès !",
    //                     icon: "question"
    //                 });

    //             } else {
    //             }
    //         } else {
    //         }
    //     } catch (error) {

    //         //alert("Erreur " + error.message);
    //     }
    // }


    const AddInter = () => {

        Swal.fire({
            title: "Voulez-vous enregistrer ce dossier ?",
            showCancelButton: true,
            confirmButtonText: "Oui",
            cancelButtonText: "Non",
            icon: 'question'
        }).then((result) => {

            if (result.isConfirmed) {

                addInterpretation();
                setSrc("");
                setSrc_1("");
                setSrc_2("");
                setOutput("");
                setOutput_1("");
                setOutput_2("");
                Swal.fire("Le dossier a été enregistré avec succès !", "", "success");
            } else if (result.isCanceled) {
                Swal.fire("Le dossier n'a pas été enregistré", "", "info");
            }
        });
    };

    // const AddCE = () => {

    //     Swal.fire({
    //         title: "Voulez-vous enregistrer ce dossier ?",
    //         showCancelButton: true,
    //         confirmButtonText: "Oui",
    //         cancelButtonText: "Non",
    //         icon: 'question'
    //     }).then((result) => {

    //         if (result.isConfirmed) {

    //             SaveCE();
    //             setSrc("");
    //             setSrc_1("");
    //             setSrc_2("");
    //             setOutput("");
    //             setOutput_1("");
    //             setOutput_2("");
    //             Swal.fire("Le dossier a été enregistré avec succès !", "", "success");
    //         } else if (result.isCanceled) {
    //             Swal.fire("Le dossier n'a pas été enregistré", "", "info");
    //         }
    //     });
    // };


    const liste_dossiers_interprete = async (id_dossier) => {
        try {


            const response = await axios.get(URL_AXIOS + `liste_interpretation/${parseInt(id_dossier)}`);

            if (response.status === 200) {
                const observation = response.data.dossierInter.observation;
                document.querySelector('.editorComponent').innerHTML = observation;
                setObservation(response.data.dossierInter.observation);
                setInter_id(response.data.dossierInter.inter_id);
                if (response.data.dossierInter.img_obs_1) {
                    const URL1 = URL_MAIN + response.data.dossierInter.img_obs_1;
                    setImg_obs_1(URL1);
                } else {
                    setImg_obs_1("");
                }
                if (response.data.dossierInter.img_obs_2) {
                    const URL2 = URL_MAIN + response.data.dossierInter.img_obs_2;
                    setImg_obs_2(URL2);
                } else {
                    setImg_obs_2("");
                }
                if (response.data.dossierInter.img_obs_3) {
                    const URL3 = URL_MAIN + response.data.dossierInter.img_obs_3;
                    setImg_obs_3(URL3);
                } else {
                    setImg_obs_3("");
                }
                setLoadingState(true);
            } else {
                setLoadingState(true);
            }

        } catch (error) {
            setLoadingState(true);
            //console.error("Error:", error.message);
            // alert("An error occurred. Please check the console for details.");
        }
    }

    const contentEditor = () => {
        return (
            <div>
                <div className='row card' style={{ height: "auto" }}>


                    <div className='col col-md-3'>
                        <h6 style={{ padding: 8, fontWeight: '600', fontSize: "13px", color: 'black' }}>{siteName} / </h6>
                        {etat === '1' && <img src="../../../assets/img/urgent.png" width="60" style={{ marginTop: 0 }} />}
                    </div>
                    <div className='col col-md-6'>
                        <h6 style={{ padding: 8, fontWeight: '600', fontSize: "13px", color: 'black', position: "absolute" }}>{categorie_fichier} / {Examen_1} {Examen_2 !== null ? "/" + Examen_2 : ""}</h6>
                    </div>
                    <div className='col col-md-3'>
                        <div style={{ display: 'flex', marginTop: "8px", color: 'green' }}>

                            {Examen_1 !== null && <h6 style={{ fontWeight: 'normal', fontSize: "13px" }}><Link title={Examen_1}
                                to={`/detail_dossier_patient/${id}`}
                                onClick={(e) => handleDownload(e, URL_FOLDER_API + chemin_fichier_1)}
                            >Examen-s1&nbsp;<i className='fa fa-cloud-download'></i>&nbsp;|</Link> </h6>}{Examen_2 !== null && <h6 style={{ fontWeight: 'normal', fontSize: "13px" }}>&nbsp;<Link
                                title={Examen_2}
                                to={`/detail_dossier_patient/${id}`}
                                onClick={(e) => handleDownload(e, URL_FOLDER_API + chemin_fichier_2)}
                                target="_blank"
                                rel="noopener noreferrer"
                            >Examen-s2<i className='fa fa-cloud-download'></i>&nbsp;|</Link></h6>}
                            {bulletin_examen !== null && <h6 style={{ fontWeight: 'normal', fontSize: "13px" }}><Link title={bulletin_examen} to={`/detail_dossier_patient/${id}`} style={{ position: 'absolute' }} onClick={(e) => handleOpen(e, URL_FOLDER_API + chemin_bulletin)}>Bulletin</Link></h6>}
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col col-md-1'>

                    </div>
                    <div className='col col-md-10'>

                        <textarea style={{ display: 'none' }} value={observation} id='textareaobs'>

                        </textarea>
                        <div style={{ display: "flex" }}>

                            <InputText type="text" value={categorie_fichier} tooltip={categorie_fichier} style={{ width: "50%", fontWeight: 'bold', fontSize: "13px", color: 'black' }} />
                            <InputText type="text" value={nom_pat} id='nom_pat' tooltip={nom_pat} style={{ width: 250, marginLeft: 10, width: "600px", fontWeight: 'bold', fontSize: "13px", color: 'black' }} />
                            <InputText type="text" value={sexe} tooltip={sexe} style={{ marginLeft: 10, fontWeight: 'bold', fontSize: "13px", width: "35px", color: 'black' }} />
                            <InputText type="text" value={age} tooltip={age} style={{ marginLeft: 10, fontWeight: 'bold', fontSize: "13px", width: "45px", color: 'black' }} />
                            <InputText type="text" value={LibelleAge} tooltip={LibelleAge} style={{ marginLeft: 10, fontWeight: 'bold', fontSize: "13px", width: "80px", color: 'black' }} />
                            <InputText type="text" value={prescripteur} tooltip={prescripteur} style={{ marginLeft: 10, fontWeight: 'bold', fontSize: "13px", color: 'black' }} />
                        </div>
                    </div>
                </div>
                <div className='row'>

                    <div style={{ display: "flex", justifyContent: "center", marginTop: "5px", }}>
                        <p style={{ fontWeight: "bold", color: "red" }}> Statut : {statut === "1" ? 'Interprété' : "Nouveau"} </p>
                        {observationDossier && <Link to={`/detail_dossier_patient/${id}`} style={{ color: "red", marginLeft: 20, fontWeight: "bold" }} onClick={() => setVisibleObserva(true)}>Voir observation du dossier</Link>}
                        {exp == 'O' &&
                            <img
                                src="../../../img/diamond_26bleupx.png"
                                style={{ marginTop: 1, height: "20px", marginLeft: 10, cursor: "pointer" }}
                                title={titleGet || 'Loading...'}
                            />}
                    </div>

                </div>
                <div class="row" style={{ marginTop: 0 }}>
                    {/*<!-- DEBUT 1ere Colonne button save & update -->*/}
                    <div class="col-md-1" style={{ height: '27vh', overflow: 'hidden' }} >

                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title"> </h4>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="table-responsive-md">
                                        <table class="table">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <button type='button' onClick={() => setVisible(true)} className="btn btn-default img_attach" style={{ width: "100%", border: 'none' }}>
                                                            <i class="icon-picture" data-toggle="tooltip" data-placement="top" title="Joindre des images sur résultats."></i>
                                                        </button>


                                                        {/*<!-- Modal -->
                                                        <div class="modal fade" id="saveModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                            <div class="modal-dialog" role="document">
                                                                <div class="modal-content">
                                                                    <div class="modal-header">
                                                                        Que voulez-vous faire ?
                                                                        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                                                                    </div>
                                                                    <div class="modal-body">
                                                                        <div class="row">
                                                                            <div class="col-md-12">
                                                                                <p style={{ textAlign: 'center', color: 'red' }}>Si votre compte rendu, a subi des modifications, veuillez d’abord effectuée une mise à jour, avant de l'enregistrer comme modèle personnalisé.</p>
                                                                            </div>
                                                                        </div>
                                                                        <hr />
                                                                        <div class="row">
                                                                            <div class="col-md-12">
                                                                                <input type="submit" onClick={() => AddInter()} value="Mise à jour du compte rendu" class="btn btn-danger" style={{ width: '100%' }} />
                                                                            </div>
                                                                        </div>
                                                                        <hr />
                                                                        <div class="row">
                                                                            <div class="col-md-12">
                                                                                <input type="button" data-toggle="modal" data-target="#titreMModal" onClick={() => AddInter()} value="Enregistrer comme modèle personnalisé" class="btn btn-success" style={{ width: '100%' }} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/*<!-- <button type="submit" onclick="enregistrer('form{{ 1 }}')" class="btn btn-default save" style="width: 100%; height: 100%;">
                                                            <i class="now-ui-icons tech_mobile" data-toggle="tooltip" data-placement="bottom" title="Enregistrer" style="color: green;"></i>
                                                            </button> -->*/}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <button type="button" data-toggle="modal" data-target="#saveModal" className="btn btn-default save"
                                                            style={{ width: '100%', height: '100%', border: 'none' }}
                                                            onClick={() => enregistrerDossier()}
                                                        >
                                                            <i class="fa fa-mobile " data-toggle="tooltip" data-placement="bottom" title="Enregistrer"></i>
                                                        </button>
                                                        <Dialog header="Que voulez-vous faire ?" visible={visibleModal} position={position} style={{ width: '50vw' }} onHide={() => setVisibleModal(false)} draggable={false} resizable={false}>
                                                            <div class="row">
                                                                <div class="col-md-12">
                                                                    <p style={{ textAlign: 'center', color: 'red', fontSize: '15px' }}>Si votre compte rendu, a subi des modifications, veuillez d’abord effectuée une mise à jour, avant de l'enregistrer comme modèle personnalisé.</p>
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <div class="row">
                                                                <div class="col-md-12">
                                                                    <button type="button" onClick={() => UpdateInterpretation()} class="btn btn-danger" style={{ width: '100%' }} >
                                                                        Mise à jour du compte rendu
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <div class="row">
                                                                <div class="col-md-12">
                                                                    <button type="button" data-toggle="modal"
                                                                        data-target="#titreMModal" onClick={() => addMcr()} class="btn btn-success" style={{ width: '100%' }} >
                                                                        Enregistrer comme modèle personnalisé
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </Dialog>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <button type="button"
                                                            onClick={() => EnvoyerDossierConfirmation()}
                                                            // data-toggle="modal" data-target="#sendModal"
                                                            className="btn btn-default send"
                                                            style={{ width: '100%', height: '100%', border: 'none' }}
                                                            disabled={statut === "1" ? false : true}
                                                        >

                                                            <i className="fa fa-send now-ui-icons ui-1_send" data-toggle="tooltip" data-placement="bottom" title="Envoyer les résultats"></i>
                                                        </button>
                                                        {/* <!-- Modal Send-->*/}
                                                        <div className="modal fade" id="sendModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                            <div className="modal-dialog" role="document">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        Question
                                                                        <button type="button" className="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                                                                    </div>
                                                                    <div class="modal-body">
                                                                        <div className="row">
                                                                            <div className="col-md-12">
                                                                                <p style={{ fontSize: '1.2em' }}>Êtes vous sûr de vouloir envoyer ces résultats ?</p>
                                                                            </div>
                                                                        </div>
                                                                        <hr />
                                                                        <div className="row">
                                                                            <div className="col-md-3"></div>
                                                                            <div className="col-md-3">
                                                                                {/*<!-- <input type="button" onclick="publier('form{{ 3 }}')" value="Oui" className="btn btn-danger" style="width: 100%;"> -->*/}
                                                                            </div>
                                                                            <div className="col-md-3">
                                                                                {/*<!-- <input type="button" value="Non" onclick="$('#sendModal').modal('toggle');" className="btn btn-dark" style="width: 100%;"> -->*/}
                                                                            </div>
                                                                            <div className="col-md-3"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    { /*<!-- FIN 1ere Colonne button save & update -->*/}

                    {/*<!-- DEBUT 2eme Colonne editeur -->*/}
                    <div className="col-md-10 pr-1" style={{ height: '72vh', overflow: 'hidden' }}>
                        {/* <!-- foreach($liste_dossiers_pl as $dossier) -->*/}
                        <div className='container-fluid'>
                            {/* <!-- endforeach -->*/}
                            {/* <div className='row'>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <p style={{ fontWeight: "bold", color: "red" }}> Statut : {statut === "1" ? 'Interprété' : "Nouveau"} </p>
                                    {observationDossier && <Link to={`/detail_dossier_patient/${id}`} style={{ color: "red", marginLeft: 20, fontWeight: "bold" }} onClick={() => setVisibleObserva(true)}>Voir observation du dossier</Link>}
                                    {exp == 'O' &&
                                        <img
                                            src="../../../img/diamond_26bleupx.png"
                                            style={{ marginTop: 1, height: "20px", marginLeft: 10, cursor: "pointer" }}
                                            title={titleGet || 'Loading...'}
                                        />}
                                </div>
                            </div> */}
                            <div className="row">

                                <div className="col-md-12">
                                    <div id="app_container">
                                        <div id='mirror' ></div>
                                        <div style={{ marginTop: 10, position: 'absolute' }}>
                                            <JoditEditor
                                                ref={editorRef}
                                                tabIndex={1}
                                                config={config}
                                                id="editorText"
                                            />

                                        </div>
                                        {/*<!-- Modal Techniques -->*/}
                                        <Dialog header="Techniques" headerStyle={{ backgroundColor: "#4ECDC4" }} visible={VisibleTech} style={{ width: '50vw' }} onHide={() => { if (!VisibleTech) return; setVisibleTech(false); }}>
                                            <div>
                                                <table id="commands_table">
                                                    <tr>
                                                        <th id="cell_say">Scanner</th>
                                                    </tr>
                                                    <tr title="Cliquez pour insérer" onClick={() => insertSymbol('Acquisition spiralée sans injection de PDC iodé, suivie d\'une reconstruction multiplanaire.')}>
                                                        <td id="cell_sad">Acquisition spiralée sans injection de PDC iodé, suivie d'une reconstruction multiplanaire.</td>
                                                    </tr>
                                                    <tr title="Cliquez pour insérer" onClick={() => insertSymbol('Acquisition spiralée sans et avec injection de PDC iodé, suivie d\'une reconstruction multiplanaire.')}>
                                                        <td id="cell_sad">Acquisition spiralée sans et avec injection de PDC iodé, suivie d'une reconstruction multiplanaire.</td>
                                                    </tr>
                                                    <tr>
                                                        <th id="cell_say">IRM</th>
                                                    </tr>
                                                    <tr title="Cliquez pour insérer" onClick={() => insertSymbol('Axiales T1, T2, FLAIR, T2* Sagittales 3DT1.')}>
                                                        <td id="cell_sad">Axiales T1, T2, FLAIR, T2* Sagittales 3DT1.</td>
                                                    </tr>
                                                    <tr title="Cliquez pour insérer" onClick={() => insertSymbol('Axiales T1, T2, FLAIR, T2*, 3DTOF Sagittales 3DT1 GADO, 2DTOF.')}>
                                                        <td id="cell_sad">Axiales T1, T2, FLAIR, T2*, 3DTOF Sagittales 3DT1 GADO, 2DTOF.</td>
                                                    </tr>
                                                    <tr title="Cliquez pour insérer" onClick={() => insertSymbol('Axiales T2, Coronales T1, T2 T1 Dynamic, T1 GADO, Sagittales T1, T2, T1 GADO.')}>
                                                        <td id="cell_sad">Axiales T2, Coronales T1, T2 T1 Dynamic, T1 GADO, Sagittales T1, T2, T1 GADO.</td>
                                                    </tr>
                                                    <tr title="Cliquez pour insérer" onClick={() => insertSymbol('DP Fastat dans les trois plans, T1 sagittales')}>
                                                        <td id="cell_sad">DP Fastat dans les trois plans, T1 sagittales.</td>
                                                    </tr>
                                                    <tr title="Cliquez pour insérer" onClick={() => insertSymbol('T2 dans les trois plans, Axiales T1, T1 Fastat GADO sagittales T1 fastat GADO')}>
                                                        <td id="cell_sad">T2 dans les trois plans, Axiales T1, T1 Fastat GADO sagittales T1 fastat GADO.</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </Dialog>

                                        <Dialog header="Raccourcis" headerStyle={{ backgroundColor: "#4ECDC4" }} visible={VisibleRaccourcis} style={{ width: '50vw' }} onHide={() => { if (!VisibleRaccourcis) return; setVisibleRaccourcis(false); }}>
                                            <div>
                                                <table id="commands_table">

                                                    <tr title="Cliquez pour insérer" onClick={() => insertSymbolRaccourcis('RENSEIGNEMENTS CLINIQUES')}>
                                                        <td id="cell_sad2">RENSEIGNEMENTS CLINIQUES.</td>
                                                    </tr>
                                                    <tr title="Cliquez pour insérer" onClick={() => insertSymbolRaccourcis('TECHNIQUE')}>
                                                        <td id="cell_sad2">TECHNIQUE.</td>
                                                    </tr>
                                                    <tr title="Cliquez pour insérer" onClick={() => insertSymbolRaccourcis('RÉSULTATS')}>
                                                        <td id="cell_sad2">RÉSULTATS .</td>
                                                    </tr>
                                                    <tr title="Cliquez pour insérer" onClick={() => insertSymbolRaccourcis('CONCLUSION')}>
                                                        <td id="cell_sad2">CONCLUSION.</td>
                                                    </tr>

                                                </table>
                                            </div>
                                        </Dialog>
                                        {/* <div class="modal right fade" id="techModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div class="modal-dialog" role="document">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    Techniques
                                                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                                                </div>
                                                <div class="modal-body">
                                                    <div>
                                                        <table id="commands_table">
                                                            <tr>
                                                                <th id="cell_say">Scanner</th>
                                                            </tr>
                                                            <tr title="Cliquez pour insérer" onClick={() => insertSymbol('Acquisition spiralée sans injection de PDC iodé, suivie d\'une reconstruction multiplanaire.')}>
                                                                <td id="cell_sad">Acquisition spiralée sans injection de PDC iodé, suivie d'une reconstruction multiplanaire.</td>
                                                            </tr>
                                                            <tr title="Cliquez pour insérer" onClick={() => insertSymbol('Acquisition spiralée sans et avec injection de PDC iodé, suivie d\'une reconstruction multiplanaire.')}>
                                                                <td id="cell_sad">Acquisition spiralée sans et avec injection de PDC iodé, suivie d'une reconstruction multiplanaire.</td>
                                                            </tr>
                                                            <tr>
                                                                <th id="cell_say">IRM</th>
                                                            </tr>
                                                            <tr title="Cliquez pour insérer" onClick={() => insertSymbol('Axiales T1, T2, FLAIR, T2* Sagittales 3DT1.')}>
                                                                <td id="cell_sad">Axiales T1, T2, FLAIR, T2* Sagittales 3DT1.</td>
                                                            </tr>
                                                            <tr title="Cliquez pour insérer" onClick={() => insertSymbol('Axiales T1, T2, FLAIR, T2*, 3DTOF Sagittales 3DT1 GADO, 2DTOF.')}>
                                                                <td id="cell_sad">Axiales T1, T2, FLAIR, T2*, 3DTOF Sagittales 3DT1 GADO, 2DTOF.</td>
                                                            </tr>
                                                            <tr title="Cliquez pour insérer" onClick={() => insertSymbol('Axiales T2, Coronales T1, T2 T1 Dynamic, T1 GADO, Sagittales T1, T2, T1 GADO.')}>
                                                                <td id="cell_sad">Axiales T2, Coronales T1, T2 T1 Dynamic, T1 GADO, Sagittales T1, T2, T1 GADO.</td>
                                                            </tr>
                                                            <tr title="Cliquez pour insérer" onClick={() => insertSymbol('DP Fastat dans les trois plans, T1 sagittales')}>
                                                                <td id="cell_sad">DP Fastat dans les trois plans, T1 sagittales.</td>
                                                            </tr>
                                                            <tr title="Cliquez pour insérer" onClick={() => insertSymbol('T2 dans les trois plans, Axiales T1, T1 Fastat GADO sagittales T1 fastat GADO')}>
                                                                <td id="cell_sad">T2 dans les trois plans, Axiales T1, T1 Fastat GADO sagittales T1 fastat GADO.</td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                        {/*  <!-- Modal Model CR -->*/}
                                        <Dialog header={"Modèles compte rendu"} headerStyle={{ backgroundColor: "#4ECDC4" }} visible={visibleMcr} style={{ width: '50vw' }} onHide={() => { if (!visibleMcr) return; setVisibleMcr(false); }}>
                                            <div className='container-fluid' style={{ marginTop: 10 }}>
                                                <div className='row'>
                                                    <input type='text' onChange={RechercheMcr} placeholder='Recherche...' className='form-control' />
                                                    {mcr.map((val, index) => (
                                                        <div class="flex flex-column md:flex-row" key={index}>

                                                            <div class="flex align-items-center  h-4rem card font-bold m-2" onClick={() => insertMrc(val.observation)} style={{ width: "96%", cursor: "pointer" }}>

                                                                <h5 style={{ marginLeft: 10 }}> {val.titre}</h5>
                                                            </div>
                                                            <div class="flex align-items-center justify-content-center  h-4rem bg-default font-bold border-round m-2" style={{ width: "4%" }}>
                                                                <button className='btn btn-danger' onClick={() => deleteConfirmMrc(val.id)} style={{}}>
                                                                    <i className='fa fa-remove' style={{ color: 'white' }}></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    ))

                                                    }
                                                    {mcr.length <= 0 && <p>Pas de modèles enregistrés</p>}
                                                </div>

                                            </div>
                                        </Dialog>
                                        {/* <div class="modal right fade" id="modelModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div class="modal-dialog" role="document">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    Modèles comptes rendus
                                                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                                                </div>
                                                <div class="modal-body" style={{ overflow: "auto", height: "500px" }}>
                                                    <div className='container-fluid'>
                                                        <div className='row'>
                                                            <input type='text' onChange={RechercheMcr} placeholder='Recherche...' className='form-control' />
                                                            {mcr.map((val, index) => (
                                                                <div class="flex flex-column md:flex-row" key={index}>

                                                                    <div class="flex align-items-center  h-4rem card font-bold m-2" style={{ width: "96%", cursor: "pointer" }}>

                                                                        <h5 style={{ marginLeft: 10 }} onClick={() => insertSymbol(val.observation)}> {val.titre}</h5>
                                                                    </div>
                                                                    <div class="flex align-items-center justify-content-center  h-4rem bg-default font-bold border-round m-2" style={{ width: "4%" }}>
                                                                        <button className='btn btn-danger' onClick={() => deleteConfirmMrc(val.id)} style={{}}>
                                                                            <i className='fa fa-remove' style={{ color: 'white' }}></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            ))

                                                            }
                                                            {mcr.length <= 0 && <p>Pas de modèles enregistrés</p>}
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                    {/*  <!-- FIN 2eme Colonne editeur-- >
    
        < !--DEBUT 3eme Colonne button aperçu, model cr, (button declencheur de modal)-->*/}
                    <div class="col-md-1" style={{ height: '33vh', overflow: 'hidden' }} id='right_options_detail_mcr'>
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title"> </h4>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="table-responsive-md">
                                        <table className="table" style={{ width: '100%' }}>
                                            <tbody >
                                                <tr>
                                                    <td>
                                                        <button onClick={(e) => handleApercu(e)} target="_blank"
                                                            disabled={statut === "1" ? false : true}
                                                            className="btn btn-default " style={{ width: '100%', color: 'red', border: 'none' }}>
                                                            <i className="fa fa-eye " data-toggle="tooltip" data-placement="bottom" title="Aperçu avant impression"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>

                                                        <button type="button" onClick={() => setVisibleMcr(true)} className="btn btn-default model" style={{ width: '100%', border: 'none' }}>
                                                            <i class="fa fa-book files_paper" data-toggle="tooltip" data-placement="bottom" title="Charger un modèle de compte rendu"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                                {/* <tr id="cvr">
                                                    <td >
                                                        <center>
                                                            <button type="button" data-toggle="modal" data-target="#cvrModal" class="btn btn-default options" style={{ width: '100%', border: 'none' }} >
                                                                <i class="fa fa-gear" data-toggle="tooltip" data-placement="bottom" title="Options"></i>
                                                            </button>
                                                        </center>

                                                    </td>
                                                </tr> */}
                                                <tr id="tech">
                                                    <td>
                                                        <button type="button" onClick={() => setVisibleTech(true)} class="btn btn-default" style={{ width: '100%', border: 'none' }}>
                                                            <i class="fa fa-tags shopping_tag-content" data-toggle="tooltip" data-placement="bottom" title="Techniques"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr id="raccourcis">
                                                    <td>
                                                        <button type="button" onClick={() => setVisibleRaccourcis(true)} class="btn btn-default" style={{ width: '100%', border: 'none' }}>
                                                            <i class="fa fa-mail-reply" data-toggle="tooltip" data-placement="bottom" title="Raccourcis"></i>
                                                        </button>
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div >
            </div>
        )
    }

    useEffect(() => {

        try {


            list_mcr(membre_id);
            liste_dossiers_avis_placeholder(membre_id);
            const fetchData = async () => {
                liste_dossiers_by_siteId(id)
                liste_dossiers_interprete(id)
                setDisable(true);
                setDisableAvis(false);
                setStatusLoad(true);
                const avisList = titles[id] || [];
                const title = avisList.map(avis => `${avis.nom_recep} ${avis.prenom_recep}`).join(', ');
                setTitleGet(title)

            };

            fetchData();
            //document.getElementById("nom_pat").innerHTML = nom_pat;
            setContent(observation)
            if (usernameconnected === null || usernameconnected === "") {
                navigate('/')
            }
            window.runOnload();
        } catch (error) {

        }

    }, [id, observation, nom_pat, statut]);


    // if (!browserSupportsSpeechRecognition) {
    //     return <span>Browser doesn't support speech recognition.</span>;
    // }

    return (
        <div>
            <Header />
            <AsideBar username={usernameconnected} />

            <Dialog header="Observation" headerStyle={{ backgroundColor: "#4ECDC4" }} visible={visibleObserva} style={{ width: '50vw' }} onHide={() => { if (!visibleObserva) return; setVisibleObserva(false); }}>
                <p style={{ marginTop: '20px' }}>
                    {observationDossier}
                </p>
            </Dialog>
            <Dialog header="Pièces jointes" headerStyle={{ backgroundColor: "#4ECDC4" }} position='top' visible={visible} onHide={() => setVisible(false)}
                style={{ width: '50vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }}>
                <div className='container-fluid'>
                    <form>

                        <div className='form-group'>
                            <div className='row'>
                                <div className={img_obs_1 ? 'col col-md-9' : 'col col-md-11'}>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={(e) => {
                                            selectImage(e.target.files[0]);
                                        }}
                                        className='form-control'
                                    />
                                </div>
                                <div className={img_obs_1 ? 'col col-md-3' : 'col col-md-1'} style={{ display: "flex", justifyContent: "space-between" }}>
                                    <h6>Fig.1</h6>
                                    {img_obs_1 && <img src={img_obs_1} style={{ width: '60px', height: '60px' }} />}
                                </div>

                            </div>
                            <div className='row'>
                                <div className='col col-md-6'>
                                    <div style={{ width: "100%", height: "30%" }}>
                                        {src && (
                                            <div>
                                                <ReactCrop onComplete={cropImageNow}
                                                    crop={crop} onChange={setCrop} >
                                                    <img src={src} id='cropp' style={{ width: "100%", height: "30%" }} />
                                                </ReactCrop>

                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className='col col-md-6'>
                                    <div style={{ width: "100%", height: "50%" }}>
                                        {output && <img src={output} style={{ width: "100%", height: "50%" }} />}
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className='form-group'>

                            <div className='row'>
                                <div className={img_obs_2 ? 'col col-md-9' : 'col col-md-11'}>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={(e) => {
                                            selectImage_1(e.target.files[0]);
                                        }}
                                        className='form-control'
                                    />
                                </div>
                                <div className={img_obs_2 ? 'col col-md-3' : 'col col-md-1'} style={{ display: "flex", justifyContent: "space-between" }}>
                                    <h6>Fig.2</h6>
                                    {img_obs_2 && <img src={img_obs_2} style={{ width: '60px', height: '60px' }} />}
                                </div>

                            </div>
                            <div className='row'>
                                <div className='col col-md-6'>
                                    <div style={{ width: "100%", height: "30%" }}>
                                        {src_1 && (
                                            <div>
                                                <ReactCrop onComplete={cropImageNow_1}
                                                    crop={crop_1} onChange={setCrop_1} >
                                                    <img src={src_1} id='cropp1' style={{ width: "100%", height: "30%" }} />
                                                </ReactCrop>

                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className='col col-md-6'>
                                    <div style={{ width: "100%", height: "50%" }}>
                                        {output_1 && <img src={output_1} style={{ width: "100%", height: "50%" }} />}
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className='form-group'>
                            <div className='row'>
                                <div className={img_obs_3 ? 'col col-md-9' : 'col col-md-11'}>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={(e) => {
                                            selectImage_2(e.target.files[0]);
                                        }}
                                        className='form-control'
                                    />
                                </div>
                                <div className={img_obs_3 ? 'col col-md-3' : 'col col-md-1'} style={{ display: "flex", justifyContent: "space-between" }}>
                                    <h6>Fig.3</h6>
                                    {img_obs_3 && <img src={img_obs_3} style={{ width: '60px', height: '60px' }} />}
                                </div>

                            </div>
                            <div className='row'>
                                <div className='col col-md-6'>
                                    <div style={{ width: "100%", height: "30%" }}>
                                        {src_2 && (
                                            <div>
                                                <ReactCrop onComplete={cropImageNow_2}
                                                    crop={crop_2} onChange={setCrop_2} >
                                                    <img src={src_2} id='cropp2' style={{ width: "100%", height: "30%" }} />
                                                </ReactCrop>

                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className='col col-md-6'>
                                    <div style={{ width: "100%", height: "50%" }}>
                                        {output_2 && <img src={output_2} style={{ width: "100%", height: "50%" }} />}
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className='form-group'>
                            <button type='button' onClick={() => UpdateInterpretationImage()} className='btn btn-success form-control'>Enregistrer</button>
                        </div>
                    </form>
                </div>
            </Dialog>
            {LoadingState && LoadingStateFolder ? <section id="main-content">
                <section class="wrapper">
                    {contentEditor()}
                </section>
            </section> : <Loading />}
            <Footer />
        </div>
    );
}

export default DetailDossiersEnvoye;